import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import {getServerUrl} from './utils/methods';

const app = createApp(App);
router.beforeEach(async (to, from, next) => {
	try {
		await getServerUrl()
		next()
	} catch (error) {
		console.error(error)
		next(false)
	}

});

app.use(router); // 使用Vue Router插件
app.mount('#app')