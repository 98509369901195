let isLoading = false
let loadingEle = null
let toastBody = null
let server = null

const urlList = [
	'https://rev0.aizhiyuan.cc',
	'https://rev1.aizhiyuan.cc',
	'https://rev0.khljbzxpeytzgsqgtfki.cc',
	'https://rev1.khljbzxpeytzgsqgtfki.cc',
	'https://rev0.nebulabinaryproxy.win',
	'https://rev1.nebulabinaryproxy.win',
	'https://rev0.goalphacloud.com',
	'https://rev1.goalphacloud.com',
]

async function getServerUrl(index = 0) {

	if (server) {
		return
	}

	showLoading()

	try {
		const url = urlList[index]
		const result = await getRequestAsync('web/test', url)
		if (result?.ok === true) {
			server = url
		} else if (index < urlList.length) {
			const newIndex = index + 1
			await getServerUrl(newIndex)
		}
	} finally {
		hideLoading()
	}

}


async function getRequestAsync (url, baseUrl) {
	const myHeaders = new Headers();
	myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	// myHeaders.append("Host", "124.220.215.2");
	myHeaders.append("Connection", "keep-alive");
	// const sessionId = localStorage.getItem('sessionId')
	// myHeaders.append("sessionId", sessionId);

	// Add cache-control headers to prevent caching
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	var requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
	};

	if (baseUrl) {
		const response = await fetch(`${baseUrl}/${url}`, requestOptions)
		return response
	} else {
		const response = await fetch(`${server}/${url}`, requestOptions)
		return response.json()
	}

}

async function postRequestAsync(url, data) {
	var myHeaders = new Headers();
	myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	// myHeaders.append("Host", "49.51.186.159");
	myHeaders.append("Connection", "keep-alive");
	// const sessionId = localStorage.getItem('sessionId')
	// myHeaders.append("sessionId", sessionId);

	// Add cache-control headers to prevent caching
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	var raw = JSON.stringify(data);

	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	};

	// const self = this

	const response = await fetch(`${server}/${url}`, requestOptions)
	return response.json()
}

function showLoading() {
	if (isLoading === false) {
		const loadingContainer = document.createElement("div")
		loadingContainer.classList.add('loader-container')
		let loadingBody = document.createElement("div")
		loadingBody.classList.add('loader');
		loadingContainer.append(loadingBody)
		// toastBody.innerText = text
		document.body.append(loadingContainer)
		isLoading = true
		loadingEle = loadingContainer
	}

}

function hideLoading() {
	if (loadingEle) {
		document.body.removeChild(loadingEle)
		isLoading = false
		loadingEle = null
	}
}

function isMobile() {
	if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
		return true
	}
	return false
}

function showToast(text) {

	if (toastBody) {
		document.body.removeChild(toastBody)
		toastBody = null
	}
	toastBody = document.createElement("div");
	toastBody.classList.add('sl-toast');
	toastBody.innerText = text
	document.body.append(toastBody)
	const timeoutID = window.setTimeout(() => {
		if (toastBody) {
			document.body.removeChild(toastBody)
			toastBody = null
		}
		window.clearTimeout(timeoutID)
	}, 3000);
}


module.exports = {
	showLoading,
	hideLoading,
	isMobile,
	postRequestAsync,
	getRequestAsync,
	showToast,
	getServerUrl
};